import React from 'react';
import {Link, graphql} from 'gatsby';
import Layout from '../components/layout';
import BackgroundImage from '../images/Header-cover.jpg';
import SEO from '../components/seo';
import Turtle from '../svg/happyturtle.svg';
import Img from 'gatsby-image/withIEPolyfill';
import Card from '../components/textcard';


const IndexPage = ({data}) => {
  const {frontmatter} = data.markdown;

  return (
    <Layout>
      <SEO title="Home" />
      <div className="bg-cover bg-center pt-32 -mt-16"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}>

        <div className="herotop container max-w-5xl flex text-white">
          <div className ="md:w-1/2 px-2">
            <h1
              className=" font-semibold text-5xl
                          leading-none">
              {frontmatter.heading}
            </h1>
            <div className="lgCTA ">
              <p className="text-xl px-2">
                {frontmatter.subheading}
              </p>
              <div className="mt-10 flex justify-center mb-24 \
                md:justify-start md:mb-48">
                <Link to="/contact">
                  <button className="btn-cta text-xl">
                    {frontmatter.action}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="phonegraphic container max-w-5xl px-2">
        <div className="content-center flex flex-row justify-between">
          <div className="md:w-1/2">
            <h2 className="font-semibold text-4xl text-gray-700">
              {frontmatter.mainpitch.title}
            </h2>
            <p className="text-gray-600 leading-loose lg:text-xl">
              {frontmatter.mainpitch.description}
            </p>
          </div>
          <div className="md:container object-center md:w-1/3">
            <Img fluid={data.graphicphone.childImageSharp.fluid}/>
          </div>
        </div>
        <div className="mt-4 flex justify-center object-bottom mb-24">
          <Link to="/contact">
            <button className="btn-cta text-xl">
              {frontmatter.action}
            </button>
          </Link>
        </div>
      </div>

      <div className="graydivide bg-gray-700">
        <div className="quote container flex flex-row max-w-5xl px-2\
          py-10 text-white">
          <div className="w-1/4 mt-4 ml-1 mr-4">
            <Img fluid={data.quote.childImageSharp.fluid} className="md:w-20 w-10"/>
          </div>
          <div className="">
            <h3 className="text-4xl">
              {frontmatter.mainpitch.quote}
            </h3>
            <h4 className="text-teal-400 font-semibold uppercase">
              {frontmatter.mainpitch.author}
            </h4>
            <br/>
          </div>
        </div>
      </div>
      <div className="categoryexamples bg-gray-100 py-24">
        <div className="anygoal container max-w-5xl px-2 text-center">
          <h2 className="font-semibold text-4xl text-gray-700">
            {frontmatter.productinfo.header}
          </h2>
          <p className="text-lg text-gray-600 ">
            {frontmatter.productinfo.description}
          </p>
        </div>

        <div className="flex w-1/3 justify-end">
          <Turtle className="flex fill-current text-teal-400 h-4 mb-1"/>
        </div>
        <div className="h-2 bg-gray-200 mb-16">
          <div className="h-2 bg-teal-400 rounded-r-full w-1/3"></div>
        </div>

        <div className=" container max-w-4xl px-2 \
          text-center md:mt-24 ">
          <ul className="flex flex-wrap justify-center items-start my-2 \
            text-lg list-none text-gray-600">
            <li className="flex flex-col justify-center md:w-1/3 m-8">
              <div className="container w-20 h-20 mb-2">
                <Img fluid={data.weight.childImageSharp.fluid}/>
              </div>
              <span className="">{frontmatter.productinfo.goals.weight}</span>
            </li>
            <li className="flex flex-col justify-center md:w-1/3 m-8">
              <div className="container w-20 h-20 mb-2">
                <Img fluid={data.happiness.childImageSharp.fluid}/>
              </div>
              <span className="">
                {frontmatter.productinfo.goals.happiness}
              </span>
            </li>
            <li className="flex flex-col justify-center md:w-1/3 m-8">
              <div className="container w-20 h-20 mb-2">
                <Img fluid={data.career.childImageSharp.fluid}/>
              </div>
              <span className="">{frontmatter.productinfo.goals.career}</span>
            </li>
            <li className="flex flex-col justify-center md:w-1/3 m-8">
              <div className="container w-20 h-20 mb-2">
                <Img fluid={data.habits.childImageSharp.fluid}/>
              </div>
              <span className="">{frontmatter.productinfo.goals.habits}</span>
            </li>
            <li className="flex flex-col justify-center md:w-1/3 m-8">
              <div className="container w-20 h-20 mb-2">
                <Img fluid={data.important.childImageSharp.fluid}/>
              </div>
              <span className="">
                {frontmatter.productinfo.goals.important}
              </span>
            </li>
          </ul>
        </div>
        <div className="my-4 lg:mt-10 flex justify-center object-bottom">
          <Link to="/contact">
            <button className="btn-cta text-xl">
              {frontmatter.action}
            </button>
          </Link>
        </div>
      </div>
      <div className="howitworks container max-w-5xl px-2 py-24">
        <div className="lg:w-4/5">
          <h2 className="font-semibold text-4xl text-gray-700">
            {frontmatter.howitworks.header}
          </h2>
          <p className="text-gray-600 leading-loose text-lg">
            {frontmatter.howitworks.description}
          </p>
          <ol className="list-none py-16 lg:w-4/5">
            <li className="inline-block flex mb-20">
              <div className="flex-none w-16 mr-4">
                <Img fluid={data.step1.childImageSharp.fluid}/>
                <div
                  className="border-r-2 h-full mt-2 w-1/2 border-purple-500"/>
              </div>
              <div>
                <span
                  className="uppercase text-purple-500 text-md m-1 \
                  mb-0 font-bold"
                >
                  Step 1
                </span>
                <h3 className="text-teal-400 font-semibold text-2xl m-1 mt-0">
                  {frontmatter.howitworks.steps.step1.header}
                </h3>
                <p className="text-gray-600 leading-loose text-lg m-1">
                  {frontmatter.howitworks.steps.step1.description}
                </p>
              </div>
            </li>
            <li className="inline-block flex mb-20">
              <div className="flex-none w-16 mr-4">
                <Img fluid={data.step2.childImageSharp.fluid}/>
                <div
                  className="border-r-2 h-full mt-2 w-1/2 border-purple-500"/>
              </div>
              <div>
                <span
                  className="uppercase text-purple-500 text-md m-1 \
                  mb-0 font-bold"
                >
                  Step 2
                </span>
                <h3 className="text-teal-400 font-semibold text-2xl m-1 mt-0">
                  {frontmatter.howitworks.steps.step2.header}
                </h3>
                <p className="text-gray-600 leading-loose text-lg m-1">
                  {frontmatter.howitworks.steps.step2.description}
                </p>
              </div>
            </li>
            <li className="inline-block flex">
              <div className="flex-none w-16 mr-4">
                <Img fluid={data.step3.childImageSharp.fluid}/>
              </div>
              <div>
                <span
                  className="uppercase text-purple-500 text-md m-1 \
                  mb-0 font-bold"
                >
                  Step 3
                </span>
                <h3 className="text-teal-400 font-semibold text-2xl m-1 mt-0">
                  {frontmatter.howitworks.steps.step3.header}
                </h3>
                <p className="text-gray-600 leading-loose text-lg m-1">
                  {frontmatter.howitworks.steps.step3.description}
                </p>
              </div>
            </li>
          </ol>
          <div className="inline-block my-4 lg:mt-10 flex object-bottom">
            <div className="w-16 mr-4"></div>
            <Link to="/contact">
              <button className="btn-cta text-xl">
                {frontmatter.action}
              </button>
            </Link>
          </div>
        </div>

      </div>
      <div className="graydivide bg-gray-100">
        <div className="quote container flex flex-row max-w-5xl px-2\
          py-10 text-white">
          <div className="md:w-1/4 mt-4 ml-1 mr-4">
            <Img fluid={data.quote.childImageSharp.fluid} className="md:w-20 w-10"/>
          </div>
          <div className="">
            <h3 className="text-4xl text-gray-600">
              {frontmatter.howitworks.quote}
            </h3>
            <h4 className="text-teal-400 font-semibold uppercase">
              {frontmatter.howitworks.author}
            </h4>
            <br/>
          </div>
        </div>
      </div>
      <div className="bg-gray-700">
        <div className="example_tg container max-w-5xl \
          px-2 py-20 text-white">
          <div className="md:w-4/5">
            <h2 className="font-semibold text-4xl">
              {frontmatter.examplegoals.header}
            </h2>
            <p className="leading-loose text-lg m-1">
              {frontmatter.examplegoals.description}
            </p>
          </div>
          <div className="flex flex-wrap justify-between">
            <div className="healthgoal flex flex-col w-full max-w-xs md:mx-2">
              <h3 className="text-purple-400 text-2xl font-semibold">
                Health
              </h3>
              {frontmatter.examplegoals.goals.health.map((goal) => {
                return ( <Card
                  key={goal.action}
                  activity={goal.action}
                  goalFrequency={goal.times}
                  weekProgress={4}
                />);
              }
              )
              }
            </div>
            <div className="careergoal flex flex-col w-full max-w-xs  md:mx-2">
              <h3 className="text-purple-400 text-2xl font-semibold">
                Career
              </h3>
              {frontmatter.examplegoals.goals.career.map((goal) => {
                return ( <Card
                  key={goal.action}
                  activity={goal.action}
                  goalFrequency={goal.times}
                  weekProgress={4}
                />);
              }
              )
              }
            </div>
            <div className="happinessgoal flex flex-col w-full max-w-xs md:mx-2">
              <h3 className="text-purple-400 text-2xl font-semibold">
                Happiness
              </h3>
              {frontmatter.examplegoals.goals.happiness.map((goal) => {
                return ( <Card
                  key={goal.action}
                  activity={goal.action}
                  goalFrequency={goal.times}
                  weekProgress={4}
                />);
              }
              )
              }
            </div>

          </div>
        </div>
      </div>
      <div className="finalcta bg-gray-100">
        <div className="quote container max-w-5xl px-2\
          py-20 text-white">
          <div className="block text-center mb-3">
            <h3 className="text-4xl text-gray-600 m-0">
              Ready To Make Progress?
            </h3>
          </div>
          <div className="flex container justify-center min-w-xs">
            <Link to="/contact" className="flex no-underline">
              <button className="btn-cta text-xl">
                {frontmatter.action}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
{
  markdown: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      title
      path
      date
      templateKey
      image
      heading
      subheading
      action
      mainpitch {
        title
        description
        quote
        author
      }
      productinfo {
        header
        description
        goals{
          weight
          career
          habits
          happiness
          important
        }
      }
      howitworks {
        header
        description
        steps {
          step1 {
            header
            description
          }
          step2 {
            header
            description
          }
          step3 {
            header
            description
          }
        }
        quote
        author
      }
      examplegoals {
        header
        description
        goals {
          health {
            action
            times
          }
          career {
            action
            times
          }
          happiness {
            action
            times
          }

        }
      }
    }
  }
  graphicphone: file(relativePath: {eq: "graphic-phone.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
        }
      }
  }
  quote: file(relativePath: {eq: "Quote-sign.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  career: file(relativePath: {eq: "icon-job.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  happiness: file(relativePath: {eq: "icon-smile.png"}) {
    childImageSharp {
      fluid(maxWidth: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  habits: file(relativePath: {eq: "icon-smoke.png"}) {
    childImageSharp {
      fluid(maxWidth: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  important: file(relativePath: {eq: "icon-star.png"}) {
    childImageSharp {
      fluid(maxWidth: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  weight: file(relativePath: {eq: "icon-weight.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  step1: file(relativePath: {eq: "steps1.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  step2: file(relativePath: {eq: "steps2.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  step3: file(relativePath: {eq: "steps3.png"}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}

  `;
