import PropTypes from 'prop-types';
import React from 'react';
import Turtle from '../svg/happyturtle.svg';

const TextCard = ({activity, goalFrequency}) => {
  return (
    <div
      className = "relative my-2 p-2 h-40 rounded \
        shadow-md bg-purple-100">
      <div className="flex mb-3">

        <h2 className="flex-1 m-2 tracking-wide capitalize \
            text-gray-700 text-lg font-semibold">
          {activity}
        </h2>

        <div className="pill w-1/3">
          <div className="w-3/4">
            <div className="mt-2 mx-auto bg-purple-500 \
            rounded-full text-center">
              <span className=" text-white">
                {goalFrequency}</span>
            </div>
          </div>
        </div>

      </div>
      <div className="absolute inset-x-0 bottom-0">
        <div className="flex container mb-1 mx-auto">
          <button
            name="complete"
            id="complete"
            // onClick = {onClick}
            className="mx-auto text-purple-600"
          >
            <Turtle className="flex fill-current text-purple-500 h-4" />
          </button>
        </div>
        <div className="progress-bar relative h-4 bg-white rounded-full">
          <div className="filler h-4 rounded-r-full bg-purple-500"
            style={{width: `50%`}}/>
        </div>
      </div>

    </div>
  );
};


TextCard.propTypes = {
  activity: PropTypes.string.isRequired,
  weekProgress: PropTypes.number.isRequired,
  goalFrequency: PropTypes.number.isRequired,
};


export default TextCard
;
